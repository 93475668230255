/* eslint-disable sonarjs/no-duplicate-string */
import Cookies from 'js-cookie';
// import { GatewayConfig } from '@cigna/cigna_csg_gsg_cipublic-shared/lib/utils/config.js';
import { ICampaignService, ICampaignInfo } from './interfaces.js';

interface stringKeyValueObj {
  [key: string]: string;
}

const { hostname, pathname, search } = location;
const cookies = document.cookie;
const parsedCookies: stringKeyValueObj = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
cookies.split(/\s*;\s*/).forEach((pair: any) => {
  pair = pair.split(/\s*=\s*/);
  const name = decodeURIComponent(pair[0]);
  const value = decodeURIComponent(pair.splice(1).join('='));
  parsedCookies[name] = value;
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const getOrgFromPath = (phoneString = false, campaignOpts: stringKeyValueObj, fileName = false) => {
  if (checkIfCigna()) {
    if (
      pathname.match(/(medicare)/gm) ||
      pathname.match(/(individuals-families\/shop-plans\/supplemental)/gm) ||
      hostname.includes('plans.cigna.com')
    ) {
      return phoneString ? 'medSuppPhone-cipublic' : fileName ? 'med-supp' : 'medSuppCampaign-cipublic';
    } else if (pathname.match(/(supplemental|csb)/gm)) {
      return phoneString ? 'csbSuppPhone-cipublic' : fileName ? 'csb-medical' : 'csbSuppCampaign-cipublic';
    } else if (pathname.match(/(dental)/gm)) {
      return phoneString ? 'ifpDenPhone-cipublic' : fileName ? 'ifp-dental' : 'ifpDenCampaign-cipublic';
    } else {
      return phoneString ? 'ifpMedPhone-cipublic' : fileName ? 'ifp-medical' : 'ifpMedCampaign-cipublic';
    }
  } else if (fileName) {
    if (campaignOpts.file === 'medSupp') {
      return 'med-supp';
    } else if (campaignOpts.file === 'csbSupp') {
      return 'csb-medical';
    } else if (campaignOpts.file === 'ifpDen') {
      return 'ifp-dental';
    } else {
      return 'ifp-medical';
    }
  } else {
    return phoneString ? `${campaignOpts.file}Phone-cipublic` : `${campaignOpts.file}Campaign-cipublic`;
  }
};

const setCampaignPhoneCookie = (
  campaignObj: stringKeyValueObj,
  isAlreadySet = false,
  campaignOpts: stringKeyValueObj,
) => {
  const valueToUse = (type: string) => (isAlreadySet ? Cookies.get(type) : campaignObj[type]);
  const currentOrganization = getOrgFromPath(true, campaignOpts);
  const updatedPhone =
    valueToUse(currentOrganization) && valueToUse(currentOrganization) !== '0'
      ? (valueToUse(currentOrganization) as string)
      : '';
  campaignObj['campaignPhone-cipublic'] = updatedPhone;
  if (isAlreadySet || !updatedPhone.length) {
    Cookies.set('campaignPhone-cipublic', updatedPhone, { domain: 'cigna.com' });
  }
  return campaignObj;
};

const validHosts = [
  'dev.cigna',
  'dcws-dev.cigna',
  'qa.cigna',
  'rel.cigna',
  'dcws-rel.cigna',
  'rel2.cigna',
  'www.cigna',
  'plans.cigna',
];
const checkIfCigna = () => validHosts.some((host) => hostname.includes(host));

const checkIfCookieExists = (cookieToCheck: string, cookiePhoneToCheck: string): boolean =>
  Object.prototype.hasOwnProperty.call(parsedCookies, cookieToCheck) &&
  parsedCookies[cookieToCheck].length > 0 &&
  Object.prototype.hasOwnProperty.call(parsedCookies, cookiePhoneToCheck) &&
  parsedCookies[cookiePhoneToCheck] !== 'undefined';

const preventCampaignsCall = (override = false, campaignOpts: stringKeyValueObj): boolean => {
  const currentOrg = getOrgFromPath(false, campaignOpts);
  const currentOrgPhone = getOrgFromPath(true, campaignOpts);
  if (override) return !override;
  if (search.length > 0 && search !== '?campaign_ID=CSBORG' && search.includes('campaign')) {
    return false;
  }
  if (
    Cookies.get('medSuppCampaign-cipublic') !== undefined &&
    Cookies.get('medSuppCampaign-cipublic') !== '' &&
    location.search === '?campaign_ID=CSBORG'
  ) {
    return true;
  }
  return checkIfCookieExists(currentOrg, currentOrgPhone);
};

const checkCampaignTracking = () => {
  const campaignTrackers = ['cid', 'afid', 'sid', 'div'];
  const params = new URLSearchParams(search);
  for (const [key, value] of params.entries()) {
    if (campaignTrackers.includes(key)) {
      Cookies.set(`campaign-${key}-cipublic`, value, { expires: 30, domain: 'cigna.com' });
    }
  }
};

export class CampaignsService implements ICampaignService {
  campaignOptions: { [key: string]: string } = {};

  constructor(
    private endpoint: string,
    private logger?: Console,
  ) {
    const campaignsMeta = (document.querySelector('meta[name="campaigns"]') as HTMLMetaElement)?.content;
    if (campaignsMeta) {
      const campaignOpts = campaignsMeta?.split(', ');
      campaignOpts.forEach((option) => {
        const optArr = option.split('=');
        this.campaignOptions[optArr[0]] = optArr[1];
      });
    }
  }

  async getCampaignsInfo(override = false): Promise<ICampaignInfo> {
    if (search && search.length) checkCampaignTracking();
    if (!this.campaignOptions['file'] && !checkIfCigna()) return { 'campaignPhone-cipublic': '' };
    if (preventCampaignsCall(override, this.campaignOptions))
      return setCampaignPhoneCookie(parsedCookies, true, this.campaignOptions);
    let results: stringKeyValueObj = {};

    try {
      const url = new URL(this.endpoint);
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          cookies: parsedCookies,
          params: search,
          pathname,
          referrer: document.referrer,
          jsonFile: getOrgFromPath(false, this.campaignOptions, true),
        }),
      });
      results = await response.json();
      results = setCampaignPhoneCookie(results, false, this.campaignOptions);
      for (const cookie in results) {
        if (results[cookie] && results[cookie].length) {
          Cookies.set(cookie, results[cookie], { expires: 30, domain: 'cigna.com' });
        }
      }
    } catch (error) {
      this.logger?.error(error);
    }

    return results;
  }
}
